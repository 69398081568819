import { deserializeProperties, Serializable, serializeProperties } from '../util/serializable.js';
import * as BABYLON from '@babylonjs/core';
import { writable, get, derived, Readable } from "svelte/store";
import { Action, IsSenderCondition } from '../services/actionManager.js';
import { project } from '../services/app.js';
import type Scene from './scene.js';
import type Node from './node.js';
import { Animation, animationFactories, AnimationType } from './animation.js';
import type { AssetSource } from '../models/assetInfo.js';
import { array } from '../util/array.js';

export interface Vector3 {
    x: number;
    y: number;
    z: number;
}

export interface Quaternion {
    x: number;
    y: number;
    z: number;
    w: number;
}

export abstract class Property {
    constructor(public docLink = '') {}
}

export class Vector3Property extends Property implements Serializable {
    public static Zero() { return new Vector3Property(); }
    public static One() { return new Vector3Property(1, 1, 1); }
    public static Unset() { return new Vector3Property(99999, 99999, 99999); }

    private _value = { x: 0, y: 0, z: 0 };
    public value = writable<Vector3>(this._value);

    public get x() { return get(this.value).x; }
    public get y() { return get(this.value).y; }
    public get z() { return get(this.value).z; }

    constructor(x = 0, y = 0, z = 0, docLink = '') {
        super(docLink);
        this.value.set({ x, y, z });
    }

    public setAxis(axis: 'x' | 'y' | 'z', value: number) {
        const newValue = this.get();
        newValue[axis] = value;
        this.value.set(newValue);
    }

    public set(v: Vector3) {
        this.value.set({ x: v.x, y: v.y, z: v.z });
    }

    public get() {
        return get(this.value);
    }

    public get isUnset() {
        return this.x === 99999 && this.y === 99999 && this.z === 99999;
    }

    static fromVector3(vec3: BABYLON.Vector3) {
        return new Vector3Property(vec3.x, vec3.y, vec3.z);
    }

    public toVector3() {
        return new BABYLON.Vector3(this.x, this.y, this.z);
    }

    public serialize() {
        return [this.x, this.y, this.z];
    }

    public deserialize(obj: any) {
        this.value.set({ x: obj[0], y: obj[1], z: obj[2] });
    }
}

export class QuaternionProperty extends Property implements Serializable {
    public static Zero() { return new QuaternionProperty(); }

    private _value = { x: 0, y: 0, z: 0, w: 0 };
    public value = writable<Quaternion>(this._value);

    public get x() { return get(this.value).x; }
    public get y() { return get(this.value).y; }
    public get z() { return get(this.value).z; }
    public get w() { return get(this.value).w; }

    constructor(x = 0, y = 0, z = 0, w = 1, docLink = '') {
        super(docLink);
        this.value.set({ x, y, z, w });
    }

    public setAxis(axis: 'x' | 'y' | 'z' | 'w', value: number) {
        const newValue = this.get();
        newValue[axis] = value;
        this.value.set(newValue);
    }

    public set(v: Quaternion) {
        this.value.set({ x: v.x, y: v.y, z: v.z, w: v.w });
    }

    public get() {
        return get(this.value);
    }

    static fromQuaternion(quat: BABYLON.Quaternion) {
        return new QuaternionProperty(quat.x, quat.y, quat.z, quat.w);
    }

    public toQuaternion() {
        return new BABYLON.Quaternion(this.x, this.y, this.z, this.w);
    }

    public serialize() {
        return [this.x, this.y, this.z, this.w];
    }

    public deserialize(obj: any) {
        this.value.set({ x: obj[0], y: obj[1], z: obj[2], w: obj[3] });
    }
}

export class BoolProperty extends Property implements Serializable {
    public value = writable(false);

    constructor(value: boolean = false, docLink = '') {
        super(docLink);
        this.value.set(value);
    }

    public serialize() {
        return get(this.value);
    }

    public deserialize(obj: any) {
        this.value.set(obj);
    }

    public set(value: boolean) {
        this.value.set(value);
    }

    public get() {
        return get(this.value);
    }

    public toggle() {
        this.set(!this.get());
    }
}

export class StringProperty extends Property implements Serializable {
    public value = writable('');

    constructor(str: string = '', docLink = '') {
        super(docLink);
        this.value.set(str);
    }

    public serialize() {
        return get(this.value);
    }

    public deserialize(obj: any) {
        this.value.set(obj);
    }

    public set(str: string) {
        this.value.set(str);
    }

    public get() {
        return get(this.value);
    }
}

export class OptionProperty extends Property implements Serializable {
    public value = writable(0);
    public selected = derived(this.value, v => this.options[v]);

    constructor(public options: string[], value = 0, public formalize = false, public hint = "", docLink = '') {
        super(docLink);
        this.value.set(value);
    }

    public set(v: number) {
        this.value.set(v);
    }

    public get() {
        return get(this.value);
    }

    public setSelected(v: string) {
        this.set(this.options.indexOf(v));
    }

    public getSelected() {
        return get(this.selected);
    }

    public serialize() {
        return get(this.value);
    }

    public deserialize(obj: any) {
        this.value.set(obj);
    }
}

export class RangeProperty extends Property implements Serializable {
    public value = writable(0);

    constructor(public min: number = 0, public max: number = 1, initialValue = min, public updateOnRelease = false, docLink = '') {
        super(docLink);
        this.value.set(initialValue);
    }

    public serialize() {
        return get(this.value);
    }

    public deserialize(obj: any) {
        this.value.set(obj);
    }

    public set(val: number) {
        this.value.set(val);
    }

    public get() {
        return get(this.value);
    }
}

export class ImageProperty extends Property implements Serializable {
    public source = writable<AssetSource>(null);

    public get() {
        return get(this.source);
    }

    public serialize() {
        return this.get();
    }

    public deserialize(obj: any) {
        this.source.set(obj);
    }

    public set(source: AssetSource) {
        this.source.set(source)
    }
}

export class ModelProperty extends Property implements Serializable {
    public source = writable<AssetSource>(null);

    public get() {
        return get(this.source);
    }

    public serialize() {
        return this.get();
    }

    public deserialize(obj: any) {
        this.source.set(obj);
    }

    public set(source: AssetSource) {
        this.source.set(source)
    }
}

export class AudioProperty extends Property implements Serializable {
    public source = writable<AssetSource>(null);
    public volume = new RangeProperty(0, 100, 50);
    public loop = writable(false);
    public playOnStart = writable(true);

    constructor() {
        super('/docs?p=sounds')
    }

    public serialize() {
        return {
            source: get(this.source),
            volume: this.volume.serialize(),
            loop: get(this.loop),
            playOnStart: get(this.playOnStart)
        };
    }

    public deserialize(obj: any) {
        this.source.set(obj.source);
        if (obj.volume) {
            this.volume.set(obj.volume);
        }
        this.loop.set(obj.loop);
        this.playOnStart.set(obj.playOnStart || false);
    }

    public copyFrom(that: AudioProperty) {
        this.source.set(get(that.source));
        this.loop.set(get(that.loop));
        this.volume.set(that.volume.get());
        this.playOnStart.set(get(that.playOnStart));
    }

    public clear() {
        this.source.set(null);
    }
}

export class AnimationProperty extends Property implements Serializable {
    public animationType = new OptionProperty([...Object.values(AnimationType)], 0, true);
    public animation = writable<Animation>(null);

    constructor() {
        super('/docs?p=animations');
        this.animationType.value.subscribe(() => {
            const animationType = this.animationType.getSelected();
            if (animationType === AnimationType.None) {
                this.animation.set(null);
            } else {
                this.animation.set(animationFactories.get(animationType)());
            }
        });
    }

    public copyFrom(other: AnimationProperty) {
        this.deserialize(other.serialize());
    }

    public get() {
        return get(this.animation);
    }

    serialize() {
        return { animationType: this.animationType.serialize(), animation: this.get()?.serialize() ?? null };
    }

    deserialize(data: any) {
        this.animationType.deserialize(data.animationType);
        this.get()?.deserialize(data.animation);
    }
}

export class ModelAnimationProperty extends Property implements Serializable {
    public animation = writable('');

    constructor(docLink = '') {
        super(docLink);
    }

    public serialize() {
        return get(this.animation);
    }

    public deserialize(obj: any) {
        this.animation.set(obj);
    }

    public get() {
        return get(this.animation);
    }

    public copyFrom(that: ModelAnimationProperty) {
        this.animation.set(get(that.animation));
    }

    public clear() {
        this.animation.set('');
    }
}

export class ActionProperty extends Property implements Serializable {
    public get action(): Action {
        return get(project).actionManager.find(
            a => {
                return a.events.some(e => e.eventName === this.eventName) &&
                    a.conditions.length === 1 &&
                    a.conditions[0] instanceof IsSenderCondition &&
                    a.conditions[0].senderId === this.senderId.get();
            });
    }

    constructor(private senderId: StringProperty, private target: any, public eventName: string, public hint = '', docLink = '') {
        super(docLink);
    }

    public dispatch() {
        get(project).actionManager.dispatchEvent(this.senderId.get(), this.target, this.eventName);
    }

    public get hasAction() {
        const action = this.action;
        return action && action.steps.length > 0;
    }

    public serialize() {}
    public deserialize(obj: any) {}
}

export class SceneProperty extends Property implements Serializable {
    public sceneId = writable('');

    constructor(str: string = '', docLink = '') {
        super(docLink);
        this.sceneId.set(str);
    }

    public serialize() {
        return this.getId();
    }

    public deserialize(obj: any) {
        this.sceneId.set(obj);
    }

    public set(scene: Scene) {
        this.sceneId.set(scene.id.get());
    }

    public get() {
        return get(project).scenes.find(s => s.id.get() === this.getId());
    }

    public getId() {
        return get(this.sceneId);
    }
}

export class NodeProperty extends Property implements Serializable {
    public nodeId = writable('');

    constructor(public filter?: (n: Node) => boolean, docLink = '') {
        super(docLink);
    }

    public serialize() {
        return this.getId();
    }

    public deserialize(obj: any) {
        this.nodeId.set(obj);
    }

    public set(node: Node) {
        this.nodeId.set(node.id.get());
    }

    public setId(id: string) {
        this.nodeId.set(id);
    }

    public get() {
        return get(get(project).scene).nodes.find(s => s.id.get() === this.getId());
    }

    public getId() {
        return get(this.nodeId);
    }
}

export class ColorProperty extends Property implements Serializable {
    public value = writable<BABYLON.Color3>(null);

    constructor(color: BABYLON.Color3 = BABYLON.Color3.White(), docLink = '') {
        super(docLink);
        this.value.set(color);
    }

    public serialize() {
        return get(this.value).toHexString();
    }

    public deserialize(obj: any) {
        this.value.set(BABYLON.Color3.FromHexString(obj));
    }

    public set(color: BABYLON.Color3) {
        this.value.set(color);
    }

    public setHex(color: string) {
        this.value.set(BABYLON.Color3.FromHexString(color));
    }

    public get() {
        return get(this.value);
    }
}

export class PropertyGroup extends Property implements Serializable {
    public value: Readable<any>;

    constructor(public option: Readable<number>, public options: any[], docLink = '') {
        super(docLink);
        this.value = derived([this.option], ([opt]) => this.options[opt]);
    }

    public serialize() {
        return serializeProperties(this.get())
    }

    public get() {
        return get(this.value);
    }

    public deserialize(obj: any) {
        deserializeProperties(this.get(), obj);
    }
}

export class ScriptProperty extends Property implements Serializable {
    public scriptId = writable('');

    public getId() {
        return get(this.scriptId);
    }

    public get() {
        return get(project).scripts.find(s => s.id.get() === this.getId());
    }

    public serialize() {
        return this.getId();
    }

    public deserialize(data: any) {
        this.scriptId.set(data);
    }
}