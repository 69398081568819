import * as BABYLON from '@babylonjs/core';
import InteractableNode from './interactableNode.js';
import { nodeFactories } from './node.js'
import { PhysicsType } from './physicsTypes.js';
import { ColorProperty, OptionProperty, PropertyGroup, RangeProperty } from "./property.js";

export enum BlockMaterial {
    Plastic = 'plastic',
    Brick = 'brick',
    Wood = 'wood',
    Concrete = 'concrete'
}

export default class BlockNode extends InteractableNode {
    public width = new RangeProperty(0.01, 100, 0.2, false, '/docs?p=blocks');
    public height = new RangeProperty(0.01, 100, 0.2, false, '/docs?p=blocks');
    public depth = new RangeProperty(0.01, 100, 0.2, false, '/docs?p=blocks');
    public material = new OptionProperty([...Object.values(BlockMaterial)], 0, true, '', '/docs?p=blocks');
    public color = new ColorProperty(BABYLON.Color3.White(), '/docs?p=blocks');
    public physics = new OptionProperty([...Object.values(PhysicsType)], 0, true, 'Requires: Physics Enabled on the scene.', '/docs?p=physics');

    public mass = new RangeProperty(0, 10, 1, false, '/docs?p=physics');
    public restitution = new RangeProperty(0, 1, 0.5, false, '/docs?p=physics');
    public friction = new RangeProperty(0, 1, 0.5, false, '/docs?p=physics');

    public physicsProperties = new PropertyGroup(this.physics.value, [{
    }, {
        restitution: this.restitution,
        friction: this.friction

    }, {
        mass: this.mass,
        restitution: this.restitution,
        friction: this.friction
    }]);

    public scaleBy(scale: number) {
        this.width.value.set(this.width.get() * scale);
        this.height.value.set(this.height.get() * scale);
        this.depth.value.set(this.depth.get() * scale);
    }

    public get type() {
        return "block";
    }

    public get inspectorProperties() { return ['width', 'height', 'depth', 'material', 'color', 'physics', 'physicsProperties', ...super.inspectorProperties]; }
}

nodeFactories.set('block', () => new BlockNode());