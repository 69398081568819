import { v4 as uuidv4 } from 'uuid';
import type Node from './node.js';
import { OptionProperty, StringProperty, ImageProperty, PropertyGroup, RangeProperty, ActionProperty, BoolProperty, Vector3Property, QuaternionProperty } from '../models/property.js';
import { serializeProperties, deserializeProperties, deserializeArray } from '../util/serializable.js';
import type { Serializable } from '../util/serializable.js';
import { nodeFactories } from './node.js';
import { environmentFactories, environmentProperties } from '../services/environment.js';
import { userStore } from '../services/store.js';

export enum BackgroundType {
    Skybox = 'skybox',
    Photo360 = '360-photo',
    AugmentedReality = 'augmented-reality'
}

const skyboxes = [
    'BlueSky',
    'BlueSunset',
    'ColdNight',
    'ColdSunset',
    'DeepDusk',
    'GloriousPink',
    'HornstullsStrand',
    'NightMoonBurst',
    'NightPath',
    'NightSky',
    'OvercastLow',
    'PondNight',
    'Powerlines',
    'SpaceAnotherPlanet',
    'SwedishRoyalCastle',
];

export enum NavigationType {
    WaypointOnly = 'waypoint-only',
    Walk = 'walk',
    Fly = 'fly',
}

export default class Scene implements Serializable {
    public id = new StringProperty(uuidv4());
    public name = new StringProperty("");

    public environment = new OptionProperty([...environmentFactories.keys()], 0, true, '', '/docs?p=environments');
    public environmentProperties = new PropertyGroup(this.environment.value, [...environmentProperties.values()].map(v => v()));

    public backgroundType = new OptionProperty([...Object.values(BackgroundType)], 0, true, '', '/docs?p=environments');
    public background = new PropertyGroup(this.backgroundType.value, [{
        skybox: new OptionProperty(skyboxes, 0, true, '', '/docs?p=environments')
    }, {
        backgroundPhoto: new ImageProperty('/docs?p=environments'),
        backgroundRotation: new RangeProperty(0, Math.PI * 2, 0, false, '/docs?p=environments')
    }, {}]);

    public navigationType = new OptionProperty([...Object.values(NavigationType)], 2, true, '', '/docs?p=navigation');

    public physicsEnabled = new BoolProperty(false, '/docs?p=physics');

    public onLoaded = new ActionProperty(this.id, this, 'scene-loaded');

    public nodes: Node[] = [];

    public thumbnail = new StringProperty('');

    public cameraPosition = new Vector3Property(0, 1.7, 0);
    public cameraRotation = new QuaternionProperty();

    constructor(name: string = "New Scene") {
        this.name.set(name);
    }

    public serialize(): any {
        return serializeProperties(this);
    }

    public deserialize(data: any, newId = false) {
        deserializeProperties(this, data);

        this.nodes = deserializeArray(data.nodes, (n: any) => {
            const factory = nodeFactories.get(n.type);
            if (factory) {
                return factory();
            } else {
                throw new Error("Unknown node type: " + n.type);
            }
        });
    }

    public onDelete() {
        if (this.thumbnail.get()) {
            userStore.delete(this.thumbnail.get());
            this.thumbnail.set('');
        }
    }

    public get inspectorProperties() { return ['environment', 'environmentProperties', 'backgroundType', 'background', 'navigationType', 'physicsEnabled', 'onLoaded']; };
};