import { writable } from "svelte/store";
import { isNode } from '../util/util.js';
import insights from "./insights.js";

const apiKey = 'AIzaSyCz3nJAqK_4q0642Th7lV89rorvIADa68g';
const fontsQuery = `https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`;
export const fontFamilies: string[] = ['default'];

interface FontInfo {
    kind: string;
    family: string;
    variants: string[];
    subsets: string[];
    version: string;
    lastModified: string;
    files: {
        regular: string;
        italic: string;
        "700": string;
        "700italic": string;
    };
}

interface FontData {
    kind: string,
    items: FontInfo[];
}

export let fontData = writable<FontData>(null);

async function loadFonts() {
    try {
        const response = await fetch(fontsQuery);
        if (!response.ok) {
            console.error(response.statusText);
            return;
        }

        const data = await response.json() as FontData;
        fontFamilies.push(...data.items.map(f => f.family));
        fontData.set(data);
    } catch (e: any) {
        console.error("Failed to load fonts: " + e.message);
        insights.trackException(e);
    }
}

if (!isNode()) {
    loadFonts();
}