import InteractableNode from './interactableNode.js';
import { nodeFactories } from './node.js'
import { PhysicsType } from './physicsTypes.js';
import { BoolProperty, ModelProperty, OptionProperty, PropertyGroup, RangeProperty } from "./property.js";

export enum PhysicsShape {
    Sphere = 'sphere',
    Box = 'box'
}

export default class ModelNode extends InteractableNode {
    public model = new ModelProperty();
    public size = new RangeProperty(0.1, 10, 1);
    public castShadows = new BoolProperty(false);
    public physics = new OptionProperty([...Object.values(PhysicsType)], 0, true, 'Requires: Physics Enabled on the scene.', '/docs?p=physics');

    public restitution = new RangeProperty(0, 1, 0.5, false, '/docs?p=physics');
    public friction = new RangeProperty(0, 1, 0.5, false, '/docs?p=physics');
    public physicsShape = new OptionProperty([...Object.values(PhysicsShape)], 0, true, '', '/docs?p=physics');
    public mass = new RangeProperty(0, 10, 1, false, '/docs?p=physics');

    public physicsProperties = new PropertyGroup(this.physics.value, [{
    }, {
        shape: this.physicsShape,
        restitution: this.restitution,
        friction: this.friction

    }, {
        shape: this.physicsShape,
        mass: this.mass,
        restitution: this.restitution,
        friction: this.friction
    }]);

    public scaleBy(scale: number) {
        this.size.value.set(this.size.get() * scale);
    }

    public get type() {
        return "model";
    }

    public get inspectorProperties() { return ['size', 'physics', 'physicsProperties', ...super.inspectorProperties]; }
}

nodeFactories.set('model', () => new ModelNode());