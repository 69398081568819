import { Writable, writable } from 'svelte/store';
import Project, { ProjectType } from '../models/project.js';
import { userStore } from '../services/store.js';
import TransitionManager from '../services/transitionManager.js';

class App {
    public project: Writable<Project> = writable(null);
    public editMode = false;
    public transitionManager = new TransitionManager();
    public takeScreenshot: () => Promise<void>;
    public showEditorInterface = writable(false);
    public showEditControls = writable(true);
    public showHierarchy = writable(true);
    public showScripts = writable(true);
    public showInspector = writable(true);
    public showSceneSelector = writable(true);
    public allowChangeSelection = writable(true);
    public enableCameraControls = writable(true);
    public fakeFullScreen = writable(false);

    public async loadProjectFromStore(name: string, startFromBeginning = false) {
        await this.loadProject(await userStore.load(name), startFromBeginning);
    }

    private async loadProject(data: Blob, startFromBeginning = false) {
        const json = JSON.parse(await data.text());
        const newProject = new Project(this.transitionManager);
        newProject.deserialize(json, startFromBeginning);
        this.project.set(newProject);
    }

    public async newProject(name: string, type: ProjectType) {
        const newProject = new Project(this.transitionManager);
        newProject.type = type;
        newProject.name.set(name);
        newProject.createFirstScene();
        this.project.set(newProject);
    }
}

export const app = new App();
export const project = app.project;